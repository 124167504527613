export const FactsInfo = [
  {
    id: "fact-1",
    text: "Last year I was living in Barcelona for nine months. My Spanish isn't as good as it could be..."
  },
  {
    id: "fact-2",
    text: "I hate coriander. Sparkling water too."
  },
  {
    id: "fact-3",
    text: "I recently learned how to solve a Rubik's cube."
  },
  {
    id: "fact-4",
    text: "I lived in the same share house in Clapham for eight years. I think it may be a record for London."
  },
]
