import Nav from '../layout/Nav';
import Hero from '../components/Hero';

function HomePage() {
  return (
    <>
      <Nav />
      <Hero />
    </>
  );
}

export default HomePage;
