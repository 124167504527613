export const LanguagesInfo = [
  {language: "JavaScript"},
  {language: "Ruby"},
  {language: "HTML"},
  {language: "CSS"},
  {language: "Tailwind"},
  {language: "TypeScript"},
  {language: "React"},
  {language: "Github"},
  {language: "Bootstrap"},
  {language: "Heroku"},
  {language: "SQL"},
  {language: "Figma"}
]
